import React from "react";
import logomain from '../assets/logomain.png';

function Footer() {
    return(
        <div className="site-footer">
             <footer>
      <section className="info_section">
            <div className="container">
                <div className="info">
                       <div className="left_block">
                          <i className="fa fa-message"></i>
                          <h4>Email Us To Know More</h4>
                          <p><a href="#">sales@shenzhen-elcom.com</a></p>
                       </div>
                       <div className="right_block">
                          <i className="fa fa-headset"></i>
                          <h4>For Technical Support Call Us At</h4>
                          <p><a href="#">+91 8866188126</a></p>
                       </div>
                       <div className="right_block">
                          <i className="fa-brands fa-linkedin"></i>
                          <h4>LinkedIn</h4>
                          <p><a href="https://www.linkedin.com/company/shenzhen-aierkang-international" target="_blank">Click Me</a></p>
                       </div>
                       <div className="right_block">
                          <i className="fa-brands fa-facebook"></i>
                          <h4>Facebook</h4>
                          <p><a href="https://www.facebook.com/shenzhenelcom" target="_blank">Click Me</a></p>
                       </div>
                </div>
            </div>
      </section>
    <div className="ft-grids">
    <div className="container">
      <div className="row">
         <div className="col">
            <div className="single-footer-widget">
               <div className="footer-heading">
                  <div style={{width: '150px'}}>
                  <img style={{borderTopLeftRadius: '40px', borderBottomRightRadius: '40px'}}
                  src={logomain} alt="image" /></div>
               </div>
               <p>Shenzhen Aierkang International, is a leading Global Distributor of Electronic Components & Systems to a wide variety of Original Equipment.</p>
            </div>
         </div>
         <div className="col">
            <div className="single-footer-widget">
               <div className="footer-heading">
                  <h3>India Office</h3>
                  <div className="bar"></div>
               </div>
               <p>SHENZHEN AIERKANG INTERNATIONAL
                  First Floor, 24 Sagar Complex, 
                  Mahavirnagar, Himmatnagar,
                  Gujarat - 383001, India</p>
            </div>
         </div>
         <div className="col">
            <div className="single-footer-widget">
               <div className="footer-heading">
                  <h3>China Office</h3>
                  <div className="bar"></div>
               </div>
               <p>SHENZHEN AIERKANG INTERNATIONAL
                  Youyi Rd, 园岭 Futian District,
                  Shenzhen, Guangdong Province, China, 518037</p>
            </div>
         </div>
         <div className="col">
            <div className="single-footer-widget">
               <div className="footer-heading">
                  <h3>Contact</h3>
                  <div className="bar"></div>
               </div>
               <div className="footer-info-contact">
                  <i className="fa fa-phone"></i>
                  <h3>Phone</h3>
                  <span><a href="#">+91 9512012125, +86 7558346733</a></span>
               </div>
               <div className="footer-info-contact">
                  <i className="fa fa-envelope"></i>
                  <h3>Email</h3>
                  <span><a href="#">sales@shenzhen-elcom.com</a></span>
               </div>
            </div>
         </div>
      </div>

      <div className="copyright_text">
          <p>© 2022 Shenzhen Aierkang International | All Rights Reserved</p>
      </div>
    </div>
   </div>
    </footer>
        </div>
    );
}

export default Footer;