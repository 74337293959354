import React, {useState} from "react";
import { Link } from 'react-router-dom';
import logomain from '../assets/logomain.png';


function Header() {
    const [isMobile, setIsMobile] = useState (false);
    return(
        <div className="site-header">
           <header>
            <div className="container">
                <nav className="navbar">
                    <div className="site-logo">
                  <div style={{width: '10%' }}>
                    <img style={{borderTopLeftRadius: '20px', borderBottomRightRadius: '20px'}} src={logomain} alt="image" /></div>
                    </div>
                    <div className="menu-list">
                    {/* <ul className={isMobile ? "nav-links-mobile" : "nav-links"} onClick={() => setIsMobile(false)}>
                        <li><Link to="">About</Link></li>
                        <li><Link to="">Services</Link></li>
                        <li><Link to="">Contact Us</Link></li> 
                    </ul> */}
                    <button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)}>
                        {isMobile ? (
                        <i className="fa fa-times"></i>)
                        : (
                        <i className="fa fa-bars"></i>
                        )}
                    </button>
                    </div>
                    {/* <div className="menu-right"> */}
                        {/* <ul> */}
                            {/* <li><Link><i className="fa fa-th-list"></i></Link></li> */}
                            {/* <li><Link to="" className="login_btn">Get A Quote</Link></li> */}
                        {/* </ul> */}
                    {/* </div> */}
                </nav>
            </div>
      </header>
        </div>
    );
}

export default Header;