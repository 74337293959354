import React, { useState, useEffect } from "react";
import 'react-jquery-plugin';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img1 from './assets/img1.jpg';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';
import img6 from './assets/img6.png';
import img7 from './assets/img7.png';
import img8 from './assets/img8.png';
import img9 from './assets/img9.png';
import img10 from './assets/img10.png';
import img11 from './assets/img11.png';
import img12 from './assets/img12.png';
import img13 from './assets/img13.png';
import img14 from './assets/img14.png';
import img15 from './assets/img15.png';
import img16 from './assets/img16.png';
import img17 from './assets/img17.png';
import img18 from './assets/img18.png';
import whatsapp from './assets/whatsapp.png';
import supplychain from './assets/supply-chain.jpg';
import solutions from './assets/solutions.jpg';
import quality from './assets/quality.jpg';
import leader from './assets/leader.jpg';
import aboutimg from './assets/about.jpg';
import sourcing from './assets/sourcing.jpg';
import project from './assets/project.jpg';
import assembly from './assets/assembly.jpg';
import './App.css';
import axios from 'axios';
import SelectMulti from 'react-select';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Helmet } from "react-helmet";
import {RotatingLines} from "react-loader-spinner";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '70%',
    overflow: 'auto',
};

const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '85%',
    overflow: 'auto',
};

const apiBaseUrl = "https://backend.shenzhen-elcom.com"

function App() {
    const [jsonData, setJsonData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [uploadFileLink, setUploadFileLink] = useState('');
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [isUploadSuccess, SetIsUploadSuccess] = useState(false);
    const [APIResponse, setAPIResponse] = useState(false);
    const [loading, setLoading] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);

    useEffect(() => {
        // setTimeout(() => setLoading(false), 10000)
        const config = {
            method: 'get',
            url: 'https://backend.shenzhen-elcom.com/api/getExcelData',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(function (response) {
                let allParts = response.data;
                allParts = allParts.map(parts => ({ ...parts, label: parts.partNumber, value: parts.partNumber }))
                setJsonData(allParts);
                const filtered = [...allParts]
                filtered.length = 10;
                setFilteredData(filtered);
                if (allParts.length > 0) {
                    setLoading(false)
                };
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const partnerimages = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 5,
            },
        },
    }

    const onInputChange = (newValue) => {
        setInputValue(newValue)
        if (newValue.length > 2) {
            let searchFiltered = [...jsonData.filter(parts => {
                return String(parts.partNumber).toLowerCase().includes(newValue.toLowerCase())
            })]
            searchFiltered.length = 10
            setFilteredData(searchFiltered)
        }
    }

    const onChangeSelect = (e) => {
        setSelectedValues(e)
    }

    const onChangeFile = (e) => {
        let file = e.target.files
        file = file[0]
        setIsFileUploading(true)
        onUploadImage(file).then(res => {
            const { body } = res
            setIsFileUploading(false)
            SetIsUploadSuccess(true)
            setUploadFileLink(`${apiBaseUrl}/${body.filename}`)
        }).catch(err => {
            setIsFileUploading(false)
        })
    }

    const onUploadImage = (file) => {
        const formdata = new FormData()
        formdata.append('file', file)

        var config = {
            method: 'post',
            url: `${apiBaseUrl}/api/upload`,
            data: formdata
        };

        return new Promise((resolve, reject) => {
            axios(config)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    reject(error)
                });

        })
    }

    const onClickEnquiryNow = () => {
        if (isFileUploading || selectedValues.length === 0) return;
        handleClose();
        handleOpen1();
    }

    const onClickFinalSubmit = () => {
        if (APIResponse) return;
        if (!name || !email || !phone || !company || !quantity) return alert('Please enter all fields');
        setAPIResponse(true);
        var data = JSON.stringify({
            "selectedValues": selectedValues,
            "name": name,
            "email": email,
            "phone": phone,
            "quantity": quantity,
            "companyName": company,
            "fileLink": uploadFileLink
        });

        var config = {
            method: 'post',
            url: 'https://backend.shenzhen-elcom.com/api/sendEmail',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                handleClose1();
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [name, setName] = React.useState('');
    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const [email, setEmail] = React.useState('');
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };
    const [phone, setPhone] = React.useState('');
    const handleChangePhone = (event) => {
        const value = event.target.value
        if (isNaN(Number(value)) || String(value).toString().length > 10) return
        setPhone(value);
    };
    const [company, setCompany] = React.useState('');
    const handleChangeCompany = (event) => {
        setCompany(event.target.value);
    };
    const [quantity, setQuantity] = React.useState('');
    const handleChangeQuantity = (event) => {
        setQuantity(event.target.value);
    };

    return (
        <div className="App" id="app-container">
            <main>

                <Helmet>
                    <title>Shenzhen Aierkang International</title>
                    <meta
                        name="description"
                        content="Shenzhen Aierkang International, is a leading Global Distributor of Electronic Components & Systems to a wide variety of Original Equipment."
                    />
                    <meta
                        name="keywords"
                        content="electronic components, embedded systems, semiconductors,
                        integrated circuits, electronic parts, electronic component distributor,
                        electronic component distributors, electronic component, buy electronic component,
                        buy electronic components, electronic components distributor, electronic parts distributor,
                        electronic components distributors, sunrom, low cost electronics in india, online electronics components,
                        arduino, ic, microcontroller, motor, crystal,lcd,  capacitor,resistance,transistor,
                        soldering components, pcb, power supply,electronics, robotics, electronics components,
                        electronic components, robotics component, electronic parts, robotics parts,
                        electronic component distributor, buy electronic components, buy electronic parts,
                        buy electronic components online, buy electronics components online India,
                        electronic components online,online electronics components, online electronic components India,
                        online components, online components India, components online,
                        online shopping electronics components, electronics components online purchase india,
                        electronics components online shop india, electronics components online shopping india,
                        ecommerce, same day shipping, shop, promotional sale, online shopping"
                    />
                </Helmet>

                <section className="banner-section">
                    <div className="container">
                        <div className="banner-content">
                            <h2 className="floating">PART SEARCH</h2>
                            <h2 className="floating" style={{ color: '#edcd1f' }}>ENGINE</h2>
                            {loading === false &&
                            <div>
                            <p>Quote Request, Inventory check, Product Specs</p>
                            <form className="search_form">
                                    <SelectMulti
                                        isMulti
                                        name="colors"
                                        inputValue={inputValue}
                                        options={filteredData}
                                        value={selectedValues}
                                        onInputChange={onInputChange}
                                        onChange={onChangeSelect}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                            </form>
                            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                                <div
                                    style={{ position: 'absolute', right: 10 }}
                                    onClick={handleOpen}>
                                    <p><a href="#" style={{ color: 'white' }}><i className="fa fa-shopping-cart"></i> View Cart ({selectedValues.length})</a></p>
                                </div>
                                <div>
                                    {isFileUploading ? <p>Uploading file...</p>
                                        : <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <label htmlFor="upload-bom-file"><i className="fa fa-cloud-arrow-up"></i> XLS</label>(Upload BOM File)
                                            {isUploadSuccess && <i class="fa-solid fa-check" style={{ color: "green" }}></i>}
                                        </p>}
                                    {selectedValues.length !== 0 && <input type="file" id="upload-bom-file" style={{ display: "none" }} onChange={onChangeFile} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />}
                                </div>
                            </div>
                        </div>
                    }
                    </div>
                {
                loading && 
                <div style={{textAlign: 'center', paddingTop: '15px'}}>
                    <h3 style={{color: 'White'}}>Please wait while we set things up for you.</h3>
                <RotatingLines width="50" strokeColor="#FF5733" />
                </div>
                }
     </div>
                </section>
                <section className="key_customers">
                    <div className="container">
                        <div className="title"><h2>PARTNERS & AFFILIATES</h2><div className="bar"></div></div>
                        <OwlCarousel className='owl-theme' loop margin={20} items={5} dots autoplay responsive={partnerimages.responsive}>
                            <div className='item'>
                                <img src={img1} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img2} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img3} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img4} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img5} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img6} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img7} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img8} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img9} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img10} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img11} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img12} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img13} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img14} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img15} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img16} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img17} alt="image" />
                            </div>
                            <div className='item'>
                                <img src={img18} alt="image" />
                            </div>
                        </OwlCarousel>
                    </div>
                </section>
                <section className="benifits_section">
                    <div className="container">
                        <div className="section-title">
                            <p>At Shenzhen Aeirkang International, we envision to position ourselves as global leaders in providing Hi-tech Products and solutions.</p>
                        </div>
                        <div className="row">
                            <div className="col col6">
                                <img style={{ borderTopLeftRadius: '40px', borderBottomRightRadius: '40px' }}
                                    src={supplychain} alt="image" /></div>
                            <div className="col col6">
                                <div className="textbox">
                                    <h2>Intelligent Supply Chain Distribution</h2>
                                    <p>The markets are continuously evolving, and with the global shifts, our adaptive and flexible distribution model helps us provide a seamless sourcing and logistics services to our clients.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col6">
                                <div className="textbox">
                                    <h2>Custom & Integrated Solutions</h2>
                                    <p>An approach to cater dedicated value-added service orientation, it is for sure that with our custom, integrated solutions our clients benefit with highest technical support, errorless response and competitive pricing.</p>
                                </div>
                            </div>
                            <div className="col col6">
                                <img style={{ borderTopLeftRadius: '40px', borderBottomRightRadius: '40px' }}
                                    src={solutions} alt="image" /></div>
                        </div>
                        <div className="row">
                            <div className="col col6">
                                <img style={{ borderTopLeftRadius: '40px', borderBottomRightRadius: '40px' }}
                                    src={quality} alt="image" /></div>
                            <div className="col col6">
                                <div className="textbox">
                                    <h2>Standard practices for Quality Assurance</h2>
                                    <p>Our team of highly dedicated engineers and technicians, our handling and testing standards ensure the authenticity, security, and quality of our products.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col6">
                                <div className="textbox">
                                    <h2> Market leadership over a wide range of applications</h2>
                                    <p>With a demonstrated history over the functioning of the application-based servicing over fields such as Aerospace, Defence, Energy, and consumer electronics, with Shenzhen Aeirkang International, you’re directly entitled to years of unparalleled market expertise.</p>
                                </div>
                            </div>
                            <div className="col col6">
                                <img style={{ borderTopLeftRadius: '40px', borderBottomRightRadius: '40px' }}
                                    src={leader} alt="image" /></div>
                        </div>
                    </div>
                </section>
                <section className="services_section">
                    <div className="container">
                        <div className="section-title">
                            <h2>SERVICES</h2>
                            <div className="bar"></div>
                        </div>
                        <div className="row">
                            <div className="col col4">
                                <div className="single-services">
                                    <img src={sourcing} alt="image" />
                                    <div className="text_block">
                                        <h3>Component Sourcing</h3>
                                        <p>Shenzhen Aierkang International is a global leader specialized in sourcing electronic components across the globe via our wide network of vendors worldwide. We help manufacturers, brokers and electronical engineers to get the parts they need when they cannot find these parts through their normal channels. Our primary focus is on active, passive and electromechanical components, and we have the ability to source other specialty products used in military, automotive, aerospace, medical and other industries.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col col4">
                                <div className="single-services">
                                    <img src={project} alt="image" />
                                    <div className="text_block">
                                        <h3>Project Management</h3>
                                        <p>Our proven methods, based on well established “Lean” & “Agile” methodologies are also available to help you manage your own projects or to train your staff how to use our project management techniques. All our projects are run as a series of modules or stages with clear costs, timescales and deliverables. Each stage provides the preparatory work to the next. We ask you to pay for 50% of each stage in advance, 40% on completion and the final 10% following a satisfactory review meeting at the end of the stage.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col col4">
                                <div className="single-services">
                                    <img src={assembly} alt="image" />
                                    <div className="text_block">
                                        <h3>Turnkey PCB Assembly</h3>
                                        <p>Shenzhen Aeirkang International PCB Assembly is a process that requires knowledge not just of PCB components and assembly but also of printed circuit board design, PCB fabrication and a strong understanding of the final product and much more. The circuit board assembly is just one piece of the puzzle to delivering the perfect product the first time - without rework or assembly errors. Shenzhen Aeirkang International is one of the Globally electronic manufacturing Services(EMS) Partnership company.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about_section">
                    <div className="container">
                        <div className="row">
                            <div className="col col6">
                                <h2>ABOUT US</h2>
                                <div className="bar"></div>
                                <p>Shenzhen Aierkang International is a global leader specialized in sourcing electronic components across the globe via our wide network of vendors worldwide. We help manufacturers, brokers and electronical engineers to get the parts they need when they cannot find these parts through their normal channels. Our primary focus is on active, passive and electromechanical components, and we have the ability to source other specialty products used in military, automotive, aerospace, medical and other industries.</p>
                                <p>As the world-leading independent electronic component distributor, Shenzhen Aierkang International owns the most plentiful product line within the distribution industry and is able to meet your demands by excellent optimization solutions to procurement and one-stop purchasing service.</p>
                                <p>As the world-leading independent electronic component distributor, Shenzhen Aierkang International owns the most plentiful product line within the distribution industry and is able to meet your demands by excellent optimization solutions to procurement and one-stop purchasing service.</p>
                            </div>
                            <div className="col col6">
                                <img style={{ borderTopLeftRadius: '100px', borderBottomRightRadius: '100px' }} src={aboutimg} alt="about-image" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="reviews_section">
                    <div className="container">
                        <div className="reviews">
                            <ul>
                                <li>
                                    <h2>2018</h2>
                                    <p>Year Established</p>
                                </li>
                                <li>
                                    <h2>632</h2>
                                    <p>Projects Completed</p>
                                </li>
                                <li>
                                    <h2>60K+</h2>
                                    <p>Components Sourced</p>
                                </li>
                                <li>
                                    <h2>231</h2>
                                    <p>Customers</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <h2 style={{ textAlign: 'center' }}>Selected Parts Details:</h2>
                        <p style={{ marginTop: '-17px', color: 'grey', textAlign: 'center' }}>Our team will contact you within 24 working hours.</p>
                        <div>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th>S.No</th>
                                    <th>Part Number</th>
                                    <th>Sai No</th>
                                    <th>Status</th>
                                    <th>Manufacturer</th>
                                    <th>Description</th>
                                </tr>
                                {selectedValues.map((value, index) =>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{value.partNumber || 'NA'}</td>
                                        <td>{value.saiNo || 'NA'}</td>
                                        <td>{value.status || 'NA'}</td>
                                        <td>{value.manufacturer || 'NA'}</td>
                                        <td>{value.description || 'NA'}</td>
                                    </tr>
                                )}
                            </table>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', marginBottom: '10px', marginTop: '15px' }}>
                            {isFileUploading ? <p>Uploading file...</p>
                                : <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <label htmlFor="upload-bom-file"><i className="fa fa-cloud-arrow-up"></i> XLS</label>(Upload BOM File)
                                    {isUploadSuccess && <i class="fa-solid fa-check" style={{ color: "green" }}></i>}
                                </p>}
                            {selectedValues.length !== 0 && <input type="file" id="upload-bom-file" style={{ display: "none" }} onChange={onChangeFile} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />}
                            <div onClick={() => onClickEnquiryNow()}
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    top: '7px',
                                    cursor: 'pointer',
                                    borderRadius: '10px',
                                    border: '1px solid black',
                                    backgroundColor: '#EDCD1F'
                                }}>
                                <p style={{ margin: '9px' }}>
                                    Save Details
                                </p>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
            <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    component="form"
                    sx={style1}
                    noValidate
                    autoComplete="off"
                >
                    <h3>Please enter your contact details:</h3>
                    <TextField
                        id="outlined-name"
                        className="input-text-field"
                        label="Name"
                        value={name}
                        onChange={handleChangeName}
                        fullWidth='true'
                        required='true'
                    />
                    <TextField
                        id="outlined-name"
                        label="Email"
                        className="input-text-field"
                        value={email}
                        onChange={handleChangeEmail}
                        fullWidth='true'
                        required
                    />
                    <TextField
                        id="outlined-name"
                        label="Phone"
                        value={phone}
                        className="input-text-field"
                        onChange={handleChangePhone}
                        fullWidth='true'
                        required
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                    <TextField
                        id="outlined-name"
                        label="Quantity"
                        value={quantity}
                        className="input-text-field"
                        onChange={handleChangeQuantity}
                        required
                        fullWidth='true'
                    />
                    <TextField
                        id="outlined-name"
                        label="Company Name"
                        value={company}
                        className="input-text-field"
                        onChange={handleChangeCompany}
                        required
                        fullWidth='true'
                    />
                    <div onClick={() => onClickFinalSubmit()}
                        style={{
                            right: 0,
                            top: '7px',
                            cursor: 'pointer',
                            borderRadius: '10px',
                            border: '1px solid black',
                            backgroundColor: '#EDCD1F'
                        }}>
                        <p style={{ margin: '9px', textAlign: 'center' }}>
                            Inquiry Now
                        </p>
                    </div>
                </Box>
            </Modal>
            <a
                href="https://wa.me/+918866188126"
                class="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={whatsapp} alt="image" />
            </a>
        </div>
    );
}

export default App;